import { AuthHeaders, SignedIn } from '../../../interfaces/Auth'
import {
  AUTHENTICATION_EXPIRED,
  AuthActionTypes,
  SIGN_FAILURE,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_OUT,
  UPDATE_AUTH_HEADERS,
} from './types'

export function signInRequest({
  email,
  password,
  token,
  provider,
}: {
  email?: string
  password?: string
  token?: string
  provider: string
}): AuthActionTypes {
  return {
    type: SIGN_IN_REQUEST,
    payload: {
      email,
      password,
      token,
      provider,
    },
  }
}

export function signInSuccess(userData: SignedIn): AuthActionTypes {
  return {
    type: SIGN_IN_SUCCESS,
    payload: userData,
  }
}

export function signFailure(errorMessage?: string): AuthActionTypes {
  return {
    type: SIGN_FAILURE,
    payload: errorMessage,
  }
}

export function signOut(): AuthActionTypes {
  return {
    type: SIGN_OUT,
  }
}

export function setAuthenticationExpired(): AuthActionTypes {
  return {
    type: AUTHENTICATION_EXPIRED,
  }
}

export function updateAuthHeaders(headers: AuthHeaders): AuthActionTypes {
  return {
    type: UPDATE_AUTH_HEADERS,
    payload: headers,
  }
}
